<template>
  <div v-show="funnel" class="module well">
    <div v-show="funnelSteps.length > 0">
      <h3>Steps</h3>
      <ul>
        <li v-for="step in funnelSteps" :key="step.index" class="list-unstyled">
          <p>{{ step.index }}. {{ step.displayName }}</p>
        </li>
      </ul>
    </div>
    <div v-show="funnelQuestions.length > 0">
      <h3>Eligibility Questions</h3>
      <ul>
        <li v-for="(question, index) in funnelQuestions" :key="index++" class="list-unstyled">
          <p>{{ index }}. {{ question.questionText }}</p>
        </li>
      </ul>
    </div>
    <!-- <div v-show="$props.funnelDocuments.length > 0"> -->
    <div v-show="funnelDocuments.length > 0">
      <h3>Funnel Documents</h3>
      <ul>
        <li v-for="(document, index) in funnelDocuments" :key="document.order" class="list-unstyled">
          <p>{{ index + 1 }}. {{ document.title }}
            <span v-if="document.optional" class="light"> (Optional)</span>
          </p>
        </li>
        <!-- <li v-for="(document, index) in $props.funnelDocuments" :key="`${index}-${document.id}`" class="list-unstyled">
          <p>{{ index + 1 }}. {{ document.title }}
            <span v-if="document.optional" class="light"> (Optional)</span>
          </p>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
import { onboarding_questions } from "@/util/funnelProgress";
export default {
  name: "TemporaryFunnel",
  props: {
    funnel: {
      type: Object,
      required: false,
    },
    // funnelDocuments: {
    //   type: Array,
    //   required: false,
    //   default: () => [],
    // },
  },
  emits: ["refetch"],
  data() {
    return {
      documents: [],
    };
  },
  computed: {
    funnelSteps() {
      return this.funnel?.steps ? this.funnel.steps : [];
    },
    funnelQuestions() {
      return onboarding_questions.questions
    },
    funnelDocuments() {
      return this.funnel?.funnelDocuments ? this.funnel.funnelDocuments : [];
    }
  },
};
</script>
