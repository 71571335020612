<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <h4>{{ clientLocations.clientName }}</h4>
        <div class="btn-container"><p>{{ clientLocations.name }}
          <span v-if="isLocationOn == true ? false : true ">
            Zone is off
          </span>
        </p></div>
        <nav class="breadcrumbs">
          <a href="/">Home</a>/<a href="/admin/onboarding" v-if="readLast">Onboarding & Maintenance /</a><a href="/admin/onboarding#locations" v-if="readLast">Zones </a><router-link v-if="!readLast" :to="{ name: 'client', params: { id: clientID } }">Zones</router-link>/
          <span class="current">{{ clientLocations.name }}</span>
        </nav>
      </div>
      <div class="header-aside">
        <search />
        <!-- <div class="button-group">
          <add-partner-button :location-id="$route.params.locationID" />
        </div> -->
      </div>
    </div>
    <tabs :tabs="tabs">
      <template #locationPartnerRequests><location-partner-requests /></template>
      <!-- <template #locationDocuments><location-documents :location-name="clientLocations.name" /></template> -->
      <!-- <template #locationContacts><location-contacts /></template>
      <template #locationFinances="{ tab }"><location-finances @onDataLoaded="updateTab(tab, $event)" /></template> -->
      <template #locationSetup="{ tab }"><location-setup :location="clientLocations" @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #locationBranding><location-branding /></template>
    </tabs>
    <add-partner-modal :location-id="$route.params.locationID" :client-name="clientLocations.clientName" :location-name="clientLocations.name" />
  </fragment>
</template>
<script>
import LocationSetup from "@/modules/admin/locations/tabs/LocationSetup";
// import LocationDocuments from "@/modules/admin/locations/tabs/LocationDocuments";
// import LocationContacts from "@/modules/admin/locations/tabs/LocationContacts";
// import LocationFinances from "@/modules/admin/locations/tabs/LocationFinances";
import LocationBranding from "@/modules/admin/locations/tabs/LocationBranding";
import Tabs from "@/components/tabs/Tabs";
// import { GET_LOCATION_DETAILS } from "@/modules/admin/locations/graph/query";
import Search from "@/components/search/Search";
import { Fragment } from "vue-fragment";
// import AddPartnerButton from "@/components/buttons/AddPartnerButton";
import AddPartnerModal from "@/modules/admin/locations/AddPartnerModal";
import LocationPartnerRequests from "@/modules/admin/locations/tabs/LocationPartnerRequests";
import ClipboardJS from "clipboard";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";

export default {
  name: "Location",
  components: {
    LocationPartnerRequests,
    AddPartnerModal,
    // AddPartnerButton,
    Search,
    Tabs,
    LocationBranding,
    // LocationFinances,
    // LocationContacts,
    // LocationDocuments,
    LocationSetup,
    Fragment,
  },
  title: "Location",
  props: {
    locationID: {
      type: String,
      required: true,
    },
    // clientID: { type: String, required: true },
  },
  computed: {
    clientID() {
      return process.env.VUE_APP_CLIENT_ID || "c8c0aa58-0a23-4ae4-9e74-4ea34fefe5fb";
    },
  },
  data: () => ({
    clientLocations: {
      clientName: "",
      name: "",
    },
    tabs: [
      { key: "locationPartnerRequests", label: "Onboarding" },
      // { key: "locationDocuments", label: "Documents" },
      // { key: "locationContacts", label: "Contacts" },
      // { key: "locationFinances", label: "Finances" },
      { key: "locationSetup", label: "Setup" },
      { key: "locationBranding", label: "Branding" },
    ],
    isLocationOn: true,
    readLast:false,
  }),
  mounted() {
    let from = localStorage.getItem("fromPage");
    // console.log(this.readLast,"this.readLast")
    if(from == "OandM"){
      this.readLast = true
    }else{
      this.readLast = false
    }
    new ClipboardJS(".btn");
    this.fetchLocationDetails(this.locationID);
  },
  methods: {
    updateTab(selectedTab, error) {
      this.tabs.filter((tab) => tab.key === selectedTab.key).forEach((tab) => (tab.error = error));
      this.tabs = [...this.tabs];
    },
    async fetchLocationDetails(locationID) {
      await restApi.post(`/location/GetLocationDetails`, encodeWithParam({ locationID })).then((data) => {
        this.clientLocations = data.data.data;
        this.isLocationOn = this.clientLocations?.onboardingFunnelStatus;
      });
    },
  },
  // apollo: {
  //   clientLocations: {
  //     query: GET_LOCATION_DETAILS,
  //     variables() {
  //       return {
  //         locationID: this.locationID,
  //       };
  //     },
  //     update: (data) => data.clientLocations[0],
  //   },
  // },
};
</script>
<style scoped>
  .btn-container p {
    font-size: 48px;
    font-size: 3rem;
    font-weight: 700;
  }
  .btn-container p span {
    grid-gap: 5px;
    grid-auto-flow: column;
    width: 92px;
    height: 19px;
    left: 491px;
    top: 171px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    background-color: #E71742;
    color: white;
    border-radius: 4px;
    padding: 5px; 
  }
  </style>
  