<template>
  <fragment>
    <div class="grid">
      <div class="col-7">
        <div class="spread">
          <div class="module-form">
            <h2>
              General Setup
              <router-link class="button secondary" v-if="getPermission('editZone')" :to="{ name: 'locationEditSetup' }">Edit Setup</router-link>
            </h2>
            <h3>Basic Info</h3>
            <div class="para-group">
              <p>{{ clientLocation.name }}</p>
              <p>{{ getTimeZone }}</p>
              <p>{{ clientLocation.startDate | formatDate }}</p>
              <p>{{ clientLocation.phone | formatPhone }}</p>
            </div>
          </div>
          <div class="module-form">
            <h3>Main Address</h3>
            <div v-if="clientLocation.address" class="para-group">
              <p>{{ clientLocation.address.address1 ? clientLocation.address.address1 : "" }}</p>
              <p>{{ clientLocation.address.address2 ? clientLocation.address.address2 : "" }}</p>
              <p>
                {{ clientLocation.address.city ? clientLocation.address.city : "" }}<span v-if="clientLocation.address.city && clientLocation.address.state">,</span>
                {{ clientLocation.address.state ? clientLocation.address.state : "" }}
              </p>
              <p>{{ clientLocation.address.postalCode ? clientLocation.address.postalCode : "" }}</p>
              <p>{{ clientLocation.address.country ? clientLocation.address.country : "" }}</p>
            </div>
          </div>
          <div class="module-form">
            <h3>Settings</h3>
            <!-- <p class="label">Federal Tax ID</p>
            <p>{{ clientLocation.federalTaxID }}</p>
            <p class="label">Tax Program</p>
            <p>{{ clientLocation.taxProgram }}</p> -->
            <p class="label">Recruiting</p>
            <p>{{ clientLocation.isRecruiting ? "On" : "Off" }}</p>
            <p class="label">HNO Age Requirement*</p>
            <p>{{ clientLocation.ageRequirement }}+</p>
            <p class="label">Earnings Provider</p>
            <div class="payment-provider">
              <!-- <div class="ach-toggle-wrapper" @click="statusChange" > -->
                <!-- <div class="on-off-toggle">
                  <input
                    :class="[paymentProvider == 'BRANCH' ? 'on-off-toggle__input active' : 'on-off-toggle__input non_active']"
                    type="checkbox" 
                  />
                  <label
                    :class="[paymentProvider == 'ACH' ? 'on-off-toggle__slider active' : 'on-off-toggle__slider non_active']"
                    class="on-off-toggle__slider"
                  ></label>
                </div> -->
                <div>Pay-fare (via Direct Deposit)</div>
              <!-- </div> -->
            </div>
            <p></p>
            <p class="label">MVR Package Name</p>
            <p>{{ getLocationConfigParam("mvrPackageName") }}</p>
            <p class="label">Screening Package Name</p>
            <p>{{ getLocationConfigParam("screeningPackageName") }}</p>
            <p>Delivery Area Map</p>
            <file-viewer :image-id="valuePopulate"></file-viewer>
          </div>

      <div id="change-provider" class="modal" aria-hidden="true">
      <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
          <div class="modal-content">
            <div class="module">
              <h5>Switch to {{paymentProvider}}</h5>
              <p>Are you sure you want to switch the location to {{paymentProvider}}?</p>
            </div>
            <div class="button-group close-top">
              <a class="button warning" data-micromodal-close @click="confirm">Confirm</a>
              <a class="button secondary" @click="cancel">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>

        <div id="modal-switch-branch" class="modal" aria-hidden="true">
      <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
          <div class="modal-content">
            <div class="module">
              <h5>Switch to Branch</h5>
              <p> <i class="icon-alert-octagon"></i> Are you sure you want to switch the location to Branch?</p>
              <p>Contact business before moving forward.</p>

            </div>
            <div class="button-group close-top">
              <a class="button warning" data-micromodal-close @click="confirm('BRANCH')">Confirm</a>
              <a class="button secondary" data-micromodal-close>Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>


          <div class="module-form">
            <h3>Account Mananger</h3>
            <p>{{ team }}</p>
          </div>
          <div class="module-form">
            <h3>OS Assignment</h3>
            <p>{{ onboardingSpecialist }}</p>
          </div>
        </div>
      </div>
      <div class="col-5 spread-aside">
        <!-- <p class="module-large">
          <a class="warning" @click="openDeleteModal"><i class="icon-plus-circle"></i>Delete This Location</a>
        </p> -->
        <div v-show="funnel" class="funnel-steps-header">
          <h3>ONBOARDING LINK</h3>
        </div>
        <div v-show="funnel" class="module" :style="{ display: 'flex', justifyContent: 'space-between', width: '100%' }">
          <input v-model="signUpLink" type="text" readonly aria-readonly="true" :style="{ marginRight: '5px' }" />
          <button class="button secondary btn" :data-clipboard-text="signUpLink" :style="{ width: '127px' }">Copy Link</button>
        </div>
        <div class="funnel-steps-header">
          <h3>Onboarding Steps</h3>
        </div>
        <div v-show="!funnel" class="well text-center module">
          <p>
            Funnel is empty
          </p>
        </div>
        <temporary-funnel :funnel="funnel" :funnel-documents="templatesWithOverrides" @refetch="refetch" />
      </div>
    </div>
  </fragment>
</template>
<script>
import { timeZones } from "@/util/timeZones";
import micromodal from "micromodal";
import { Fragment } from "vue-fragment";
import FileViewer from "@/components/files/FileViewerLocation";
import TemporaryFunnel from "@/modules/admin/locations/tabs/TemporaryFunnel";
import { restApi } from "@/http/http.config";
import {encodeWithParam , encode} from "@/util/Base64Encoding";
// import InputSwitch from 'primevue/inputswitch';
import { convertBufferToContent } from "@/util/BufferContentConverter";
import { mapGetters } from "vuex";
import {onboarding_steps} from "@/util/funnelProgress";

export default {
  name: "LocationSetup",
  components: { TemporaryFunnel, FileViewer, Fragment }, //DeleteClientLocationModal
  data() {
    return {
      funnel: {},
      paymentProvider: "",
      switchStatus: false,
      clientLocation: {
        name: "",
        client: {
          name: "",
        },
        partners_aggregate: {
          aggregate: {
            count: 0,
          },
        },
        imageFile: {
          path: "",
          name: "",
          public: false,
        }
      },
      templates: [],
      overrides: [],
      isoverrideDone: false,
      deliveryAreaMapBuffer: "",
    };
  },
  mounted() {
    this.fetchLocationDetails(process.env.VUE_APP_CLIENT_ID, this.$route.params.locationID);
  },
  computed: {
    ...mapGetters(["getPermission"]),
    valuePopulate(){
        return this.deliveryAreaMapBuffer;
    },
    signUpLink() {
      return `${process.env.VUE_APP_PARTNER_PORTAL_BASE_URL}/ca-signup?locationId=${this.$route.params.locationID}&name=${this.clientLocation.name?.replace(
        / /g,
        "%20"
      )}`;
    },
    onboardingSpecialist() {
      if (!this.clientLocation.teamSpecialist) {
        return "none";
      }
      const employee = this.clientLocation.teamSpecialist;
      const fullName = [employee.firstName, employee.lastName].join(" ");
      return fullName + " - " + employee.role;
    },
    getTimeZone() {
      if (this.clientLocation.timeZone) {
        let obj = timeZones.filter((zone) => {
          return zone.value === this.clientLocation.timeZone;
        });
        return obj[0]?.displayString;
      } else {
        return "";
      }
    },
    team() {
      if (!this.clientLocation.accountManager) {
        return "none";
      }
      const employee = this.clientLocation.accountManager;
      const fullName = [employee.firstName, employee.lastName].join(" ");
      return fullName + " - " + employee.role;
    },
    templatesWithOverrides() {
      if (this.isoverrideDone) {
        return this.templates
          .filter(() => {
            return !this.overrides.some((override) => {
              return override.locationID === location.id && override.type === "REMOVE";
            });
          })
          .filter((template) => !this.overrides.some((item) => item.templateID === template.id))
          .map((template) => {
            if (template.isDDI || (template.clientID != null && template.locationID == null)) {
              template.inherited = true;
            }
            return template;
          });
      }
      return [];
    },
  },
  methods: {
    statusChange() {
      if (this.paymentProvider != 'BRANCH') {
        this.paymentProvider = "BRANCH"
        micromodal.show("change-provider", {});
      } else if (this.paymentProvider != 'ACH') {
        this.paymentProvider = "ACH"
        micromodal.show("change-provider", {});
      }
    },
    confirm(){
      if (this.paymentProvider == 'BRANCH') {
        let param = {
          locationID: this.$route.params.locationID,
          multipePay: false
        }
        this.updatePaymentProcessor(param)
      }

      if (this.paymentProvider == 'ACH') {
        let param = {
          locationID: this.$route.params.locationID,
          multipePay: true
        }
        this.updatePaymentProcessor(param)
      }
    },

    cancel(){
      micromodal.close("change-provider")
      this.fetchLocationDetails(process.env.VUE_APP_CLIENT_ID, this.$route.params.locationID);

    },

    updatePaymentProcessor(param){
           restApi.post('location/changeProvider', encodeWithParam(param))
        .then(() => {
             this.fetchLocationDetails(process.env.VUE_APP_CLIENT_ID, this.$route.params.locationID);
        });
    },
    branchConfirm(){
        const param = {
        locationID : this.$route.params.locationID,
        paymentProcessor: "BRANCH",
        oneSwitch: false
      }
      restApi.post('location/switchToOne', encodeWithParam(param))
        .then(() => {
             this.fetchLocationDetails(process.env.VUE_APP_CLIENT_ID, this.$route.params.locationID);
         
        });
    },
    async fetchLocationDetails(clientID, id) {
      await restApi.post(`/location/getLocationSetup`, encodeWithParam({clientID, id})).then((responseData)=>{
        let data = responseData.data.data;
        console.log('this is data', data)
        this.clientLocation = data.clientLocation;
        this.switchStatus = data.clientLocation.oneSwitch;
        this.paymentProvider = data.clientLocation.isMultiplePayEnabled ? 'ACH' : 'BRANCH' ;
        this.clientLocation.name =encode(data.clientLocation.name);
        // console.log('this is data', this.clientLocation.name)

        const error = {
          showWarning: true,
        };
        
        // here need to make changes


        // data?.clientLocation?.funnel.steps.splice(4, 1);
        // for (let i = 0; i < data?.clientLocation?.funnel.steps.length; i++) {
        //   data.clientLocation.funnel.steps[i].index = i + 1;
        //   //removing 4)documents and rearranging the index of arr
        //   // console.log(data?.clientLocation?.funnel.steps[i].index, "asdhasudhkasduh")
        //   }

        // this.funnel = data?.clientLocation?.funnel;

        this.funnel = onboarding_steps;

        if (this.funnel?.id) {
          error.showWarning = false;
        } else {
          error.title = "Funnel Setup Not Complete";
          error.description = "Funnel must be setup to onboard candidates.";
        }
        this.$emit("onDataLoaded", error);
      });
      if(this.clientLocation.deliveryAreaMap) {
        let imageBuffer = await restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: this.clientLocation.deliveryAreaMap}))
         this.deliveryAreaMapBuffer = convertBufferToContent(imageBuffer.data.bufferInHex,imageBuffer.data.contentType)
      }
    },
    getLocationConfigParam(paramName) {
      return this.clientLocation && this.clientLocation.locationConfiguration ? this.clientLocation.locationConfiguration[paramName] : "No Location Specific Package - Using Client Default";
    },
    refetch() {
      this.$apollo.queries.clientLocation.refetch();
    },
    openDeleteModal() {
      micromodal.show("modal-delete-client-location", {});
    },
  },
};
</script>