<template>
  <fragment>
    <span>{{restAPI}}</span>
    <h2>Onboarding</h2>
    <div class="module-xlarge">
      <h3>Current Active Partners</h3>
      <div class="flex">
        <div class="current-active-partners">
          <div class="active-partners">{{ activePartners }}</div>
          <div class="title">Current Active Roster</div>
        </div>
        <tooltip
          id="active-partners-tooltip"
          title=""
          description="Active partners are partners who have rendered services for this location in the latest completed pay cycle and newly approved partners."
          hover
        />
        <location-enrollment-status :entries="currentWeek" :location-specific="true"/>

        <!--        <data-table :headers="locationHeaders" :entries="[location]">-->
<!--          <template #progress="{ item }">-->
<!--            <location-progress-cell :location="item" :requested="requestedCount(item)" :approved="approvedCount(item)"/>-->
<!--          </template>-->
<!--          <template #noContents>The selected employee does not have any locations assigned to them. An account manager can make assignments!</template>-->
<!--        </data-table>-->
      </div>
    </div>
    <div class="module-xlarge">
      <h3>New and Active Requests</h3>
      <data-table :headers="activeRequestHeaders" :entries="activeRequests">
        <template #week="{ item }">{{ item.startDate | formatDate }} - {{ item.endDate | formatDate }}</template>
        <template #count="{ item }">
          <strong v-if="item.count == 0 && !item.editing">no request</strong>
          <fragment v-if="item.count != 0 && !item.editing">{{ item.count }}</fragment>
          <div v-if="item.editing" class="spread-number">
            <input
              :ref="item.key"
              v-model="item.count"
              :name="item.key"
              type="number"
              min="1"
              max="10"
              @input="updateCount(item, $event)"
              @keyup.enter="submit(item)"
              @focus="$event.target.select()"
            />
          </div>
        </template>
        <template #actions="{ item }">
          <a v-if="!item.count && !item.editing && !item.locked && (getPermission('editHCR'))" @click="edit(item)">Make Request</a>
          <a v-if="item.count && !item.editing && !item.locked && (getPermission('editHCR'))" @click="edit(item)">Edit</a>
          <fragment v-if="item.locked">locked</fragment>
          <a v-if="item.editing" class="button secondary small" @click="submit(item)">Submit</a>
        </template>
      </data-table>
    </div>
    <div class="module-xlarge">
      <h3>Past Requests</h3>
      <p class="label">Filters</p>
      <base-filter name="Show the Last" :options="filterOptions" type="radio" @onFilter="selectedFilters = $event" />
      <data-table :headers="headers" :entries="filteredList">
        <template #week="{ item }">{{ item.startDate | formatDate }} - {{ item.endDate | formatDate }}</template>
        <template #fulfilled="{ item }"><small-progress-bar :complete="item.approved" :total="item.total" /></template>
      </data-table>
    </div>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { Tooltip, BaseFilter, DataTable, TableHeader, SmallProgressBar, Direction } from "@/components";
// import { GET_LOCATION_REQUESTS, GET_ON_HOLD_CANDIDATES } from "@/modules/clientPortal/queries";
import DateTime from "luxon/src/datetime";
// import { SAVE_HEAD_COUNT_REQUEST } from "@/modules/clientPortal/mutations";
// import { SET_APPROVAL_DATE, MARK_CANDIDATE_STATUS, CREATE_CANDIDATE_PROGRESS } from "@/modules/admin/onboarding/graph/mutations";
// import LocationProgressCell from "@/modules/admin/clients/locations/LocationProgressCell";

import { mapGetters } from "vuex";
import LocationEnrollmentStatus from "@/components/LocationEnrollmentStatus";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
export default {
  components: { LocationEnrollmentStatus, SmallProgressBar, DataTable, Tooltip, Fragment, BaseFilter,
    // LocationProgressCell
  },
  filters: {
    formatDate(date) {
      let dateToFormat = date;
      if (typeof date === "string") {
        dateToFormat = DateTime.fromISO(date);
      }
      return dateToFormat.toFormat("dd LLL");
    },
  },
  data: () => ({
    selectedFilters: [],
    activeRequests: [],
    requestUpdate: {},
    locationData: {},
    startDayWeek: 6,
    endDayWeek: 5,
    location:null,
    existingHCR: []
  }),
  
  computed: {
    ...mapGetters(["getUsername","getSelectedEmployee" , "getPermission"]),
    restAPI() {
      this.getLocationRequests(this.$route.params.locationID, process.env.VUE_APP_CLIENT_ID);
      return "";
    },
    currentWeek(){
      if(this.location?.headCountRequests?.length > 0) {
        const headcountRequest = this.location.headCountRequests.filter(item => item.endDate.substring(0, 10) >= this.existingHCR[0].startDate).filter(item => item.startDate.substring(0, 10) <= this.existingHCR[0].endDate).filter(item => new Date(item.startDate.substring(0, 10)) <= new Date());

        // const headcountRequest = this.location.headCountRequests.filter(item => item.endDate.substring(0, 10) >= DateTime.local(DateTime.now()).startOf("week").minus({ days: 2 }).toISODate()).filter(item => item.startDate.substring(0, 10) <= DateTime.local(DateTime.now()).startOf("week").minus({ days: 2 }).toISODate());
        // const headcountRequest = this.location.headCountRequests.map((item) => {
          //  DateTime.local(DateTime.now()).startOf("week").plus({ days: 5 }).toISODate() > item.startDate
        //   console.log("item", item);
        //   if (item.startDate == DateTime.local(DateTime.now()).startOf("week").toISODate()) {
        //     return { ...item };
        //   } else if (item.startDate >= DateTime.local(DateTime.now()).startOf("week").minus({ days: 2 }).toISODate()) {
        //     return { ...item };
        //   }
        // });
        // console.log("headcountRequest after filter", headcountRequest);
        const location = this.location;
        // delete location.headCountRequest;
        location.headCountRequests = headcountRequest;
        return [location];
      }else{
        return [this.location]
      }
    },
    filterOptions() {
      return [
        { id: "filter-show-last-1", name: "last1", label: "1 Month" },
        { id: "filter-show-last-3", name: "last3", label: "3 Months" },
        { id: "filter-show-last-6", name: "last6", label: "6 Months" },
        { id: "filter-show-last-year", name: "lastYear", label: "1 Year" },
        { id: "filter-show-last-all", name: "allRequests", label: "All Requests" },
      ];
    },
    headers() {
      return [
        new TableHeader({ name: "week", cellContents: "week", headerClass: "cell20", label: "Week",sortable:true,sorted:true, sortFunction:this.sortPastRequests, direction: Direction.DESC}),
        new TableHeader({ name: "count", cellContents: "count", headerClass: "cell20", label: "Additional Partners Requested" }),
        new TableHeader({ name: "approved", cellContents: "approved", headerClass: "cell20", label: "Partners Approved" }),
        new TableHeader({ name: "fulfilled", cellContents: "fulfilled", headerClass: "cell20", label: "Request Fulfilled" }),
        new TableHeader({ name: "total", cellContents: "total", headerClass: "cell20", label: "Resulting Active Partners" }),
      ];
    },
    locationHeaders() {
      return [
        new TableHeader({ label: "Progress", headerClass: "cell15", name: "progress", sortable: false }),
        new TableHeader({ label: "Requested", headerClass: "cell0", cellContents: this.requestedCount, sortable: false }),
        new TableHeader({ label: "Application", headerClass: "cell10", cellContents: this.applicationCount, sortable: false }),
        new TableHeader({ label: "Review", headerClass: "cell10", cellContents: this.reviewCount, sortable: false }),
        new TableHeader({ label: "Enrollment", headerClass: "cell10", cellContents: this.enrollmentCount, sortable: false }),
        new TableHeader({ label: "Risk Mitigation", headerClass: "cell10", cellContents: this.riskCount, sortable: false }),
        new TableHeader({ label: "Completion", headerClass: "cell10", cellContents: this.completionCount, sortable: false }),
        new TableHeader({ label: "Approval", headerClass: "cell10", cellContents: this.approvedCount, sortable: false }),
      ];
    },
    filteredList() {
      if (this.location && this.location.headCountRequests) {
        return this.location.headCountRequests
          .filter((headCountRequest) => {
            if (this.isThisWeek(headCountRequest.startDate) || this.isNextWeek(headCountRequest.startDate) || this.isFiltered(headCountRequest.startDate)) {
              return false;
            }
            return true;
          })
          .map((headCountRequest) => ({
            ...headCountRequest,
            count: headCountRequest.count ? headCountRequest.count : 0,
            approved: this.previousApproved(headCountRequest),
            total: headCountRequest.count ? headCountRequest.count : 0,
          }))
      }
      return [];
    },
    startOfWeek() {
      // return DateTime.local(DateTime.now()).startOf('week').minus({days:2}).toISODate();
      return DateTime.fromISO(this.existingHCR[0]?.startDate).toISODate()
    },
    endOfWeek() {
      // const difference = Number(this.startDayWeek) - Number(this.endDayWeek);
      // const days = this.startDayWeek >= this.endDayWeek ? 7 - Math.abs(difference) : Math.abs(difference);
      // return DateTime.fromISO(this.startOfWeek).endOf("day").plus({ days: days });
      return DateTime.fromISO(this.existingHCR[0]?.endDate)
    },
    startOfNextWeek() {
      // return DateTime.fromISO(this.startOfWeek).startOf("day").plus({ days: 7 });
      return DateTime.fromISO(this.existingHCR[1]?.startDate)
    },
    endOfNextWeek() {
      // return DateTime.fromISO(this.endOfWeek).endOf("day").plus({ days: 7 });
      return DateTime.fromISO(this.existingHCR[1]?.endDate)
    },
    endOfFirstRange() {
      return DateTime.fromISO(this.existingHCR[0]?.endDate);
    },
    activePartners() {
      if (this.location && this.location.partners) {
        return this.location.partners.aggregate.count;
      }
      return 0;
    },
    noRequest() {
      if (this.location && this.location.headCountRequests) {
        return this.location.headCountRequests.filter((headCountRequest) => headCountRequest.startDate === this.startOfWeek).length === 0;
      }
      return true;
    },
    activeRequestHeaders() {
      return [
        new TableHeader({ name: "week", cellContents: "week", headerClass: "cell60", label: "Time Period" }),
        new TableHeader({
          name: "count",
          cellContents: "count",
          headerClass: "cell20",
          label: "Additional Partners Requested",
          cellClass: (item) => (!item.count && !item.editing ? "red" : ""),
        }),
        new TableHeader({ name: "actions", cellContents: "actions", headerClass: "cell20", label: "", cellClass: "table-actions" }),
      ];
    },
    activeWeeks() {
      if (this.existingHCR.length == 1) {
        return [
          { key: "thisweek", startDate: this.startOfWeek, endDate: this.endOfWeek },
        ];
      } else {
        return [
          { key: "thisweek", startDate: this.startOfWeek, endDate: this.endOfWeek },
          { key: "nextweek", startDate: this.startOfNextWeek, endDate: this.endOfNextWeek },
        ];
      }
    },
    locationID() {
      return this.$route.params.locationID;
    },
  },
    
    
  methods: {
    async getLocationRequests(locationID, clientID) {
       return restApi.get(`/location/getPendingHCR`).then(async (data) => { 
        this.existingHCR = data.data.pendingHCR
      await restApi.post(`/location/getLocationRequests`, encodeWithParam({locationID, clientID, userID: this.getSelectedEmployee.id, startDate: this.startOfWeek})).then((responseData)=>{
        // console.log("data getLocationRequests", responseData);
        let data = responseData.data.data;
       
        const timePeriod = data?.timePeriods;
        this.location = data.location;
        const location = data.location;
        // console.log("data getLocationRequests", data);
        if (location && location.headCountRequests) {
          this.activeRequests = this.activeWeeks.map((week) => {
            if (week.key === "thisweek") {
              // const local = DateTime.local().setZone("America/Los_Angeles");
              // const rezoned = local.setZone("America/Los_Angeles").set({ weekday: 5, hour: 0, minutes: 0, seconds: 0, millisecond: 0 });
              // const locked = rezoned < local;
              let todaysDate=DateTime.local().setZone("America/Los_Angeles").toFormat('yyyy-MM-dd');
                 let dateRecieved = `${(this.endOfFirstRange)}`.split("T")[0];
                  let locked = false;
                  if(todaysDate ==dateRecieved){
                    locked = true
                  }
              week.locked = locked;console.log(todaysDate,dateRecieved,"dsdsdsdsds")
            }
            let findWeek = -1;
            if (this.location?.headCountRequests) {
              findWeek = this.location.headCountRequests.findIndex((headCountRequest) => {
                return this.$options.filters.formatDate(headCountRequest.startDate) === this.$options.filters.formatDate(week.startDate);
              });
            }
            if (findWeek >= 0) {
              const request = this.location.headCountRequests[findWeek];
              return  { ...request, ...week };
            }
            return  { ...week };
          });
        } else {
          this.activeRequests = this.activeWeeks;
        }
        this.calculateWeekDay(timePeriod);
      });
      });
    },
    sortPastRequests(){
    return (item1, item2)=> {
      return  DateTime.fromISO(item1.startDate).toISO() -DateTime.fromISO(item2.startDate).toISO()
    }
  },
    previousApproved(request){
      return this.location.stageCounts
        .filter((item) => {
          if (item.stage === "APPROVAL" && DateTime.fromISO(item.weekly) >= DateTime.fromISO(request.startDate) && DateTime.fromISO(item.weekly) <= DateTime.fromISO(request.endDate) ) {
            return item;
          }
        })
        .reduce((start, next) => start + next.count, 0);
    },
    previousRequested(item){
      console.log(item)
      return 0
    },
    isThisWeek(date) {
      return this.$options.filters.formatDate(date) === this.$options.filters.formatDate(this.startOfWeek);
    },
    isNextWeek(date) {
      return this.$options.filters.formatDate(date) === this.$options.filters.formatDate(this.startOfNextWeek);
    },
    isFiltered(date) {
      if (this.selectedFilters && this.selectedFilters.length > 0) {
        const filter = this.selectedFilters[0];
        const datetime = DateTime.fromISO(date);
        switch (filter) {
          case "last1":
            return datetime < DateTime.utc().startOf("day").minus({ months: 1 });
          case "last3":
            return datetime < DateTime.utc().startOf("day").minus({ months: 3 });
          case "last6":
            return datetime < DateTime.utc().startOf("day").minus({ months: 6 });
          case "lastYear":
            return datetime < DateTime.utc().startOf("day").minus({ years: 1 });
          case "allRequests":
            return true;
        }
      }
    },
    applicationCount(location) {
      return this.stageCount(location, "APPLICATION");
    },
    reviewCount(location) {
      return this.stageCount(location, "REVIEW");
    },
    enrollmentCount(location) {
      return this.stageCount(location, "ENROLLMENT");
    },
    riskCount(location) {
      return this.stageCount(location, "RISK_MITIGATION");
    },
    completionCount(location) {
      return this.stageCount(location, "COMPLETION");
    },
    approvedCount(location) {
      return location.stageCounts.filter(item=> {
        if (item.stage === "APPROVAL" && DateTime.fromISO(item.weekly) >= DateTime.local(DateTime.now()).startOf("week").minus({ days: 2 })) {
          return item
        }
      }).reduce((start, next) => start + next.count, 0);
      // return this.stageCount(location, "APPROVAL");
    },
    stageCount(location, stage) {
      return location?.stageCounts?.filter((stageCount) => stageCount.stage === stage).reduce((start, next) => start + next.count, 0);
    },
    requestedCount(location) {
      return location?.headCountRequests?.length>0 ? location.headCountRequests[0].count : 0
      // return location?.headCountRequests?.filter((countRequest) => this.isThisWeek(countRequest.startDate)).reduce((start, next) => start + next.count, 0);
    },
    getHeadCountFulfilledPercent(complete, total) {
      return !total || total === 0 ? 100 : complete / total / Math.pow(10, -2);
    },
    updateCount(request, event) {
      this.requestUpdate[`${request.key}`] = event.target.value;
    },
    edit(request) {
      this.activeRequests = this.activeRequests.map((activeRequest) => {
        if (activeRequest.key === request.key) {
          return { ...activeRequest, editing: true };
        }
        return activeRequest;
      });
      this.$nextTick(() => this.$refs[`${request.key}`].focus());
    },
    submit(request) {
      let req = { ...request, count: this.requestUpdate[`${request.key}`] };
      const headCountRequest = {
        ...req,
        startDate: DateTime.fromISO(request.startDate).toFormat("yyyy-MM-dd"),
        endDate: DateTime.fromISO(request.endDate).toFormat("yyyy-MM-dd"),
        requestedBy: this.getUsername,
        requestDate: new Date(),
        clientLocationID: this.locationID,
      };
      delete headCountRequest.locked;
      delete headCountRequest.key;
      delete headCountRequest.editing;
      delete headCountRequest["__typename"];
      // console.log("before api call headCountRequest", headCountRequest);
      restApi.post(`/location/updateHeadCountRequest`, encodeWithParam({headCountRequest})).then(async ()=>{
        const requestCount = this.requestUpdate[`${request.key}`];
        await this.approveOnHoldPartners(requestCount);
        this.requestedCount = requestCount;
        this.activeRequests = this.activeRequests.map((activeRequest) => {
          if (activeRequest.key === request.key) {
            return {
              ...activeRequest,
              editing: false,
              count: requestCount,
            };
          }
          return activeRequest;
        });
        await this.getLocationRequests(this.$route.params.locationID, process.env.VUE_APP_CLIENT_ID);
      });
      /*this.$apollo.mutate({ mutation: SAVE_HEAD_COUNT_REQUEST, variables: { headCountRequest } }).then(() => {
        const requestCount = this.requestUpdate[`${request.key}`];
          this.approveOnHoldPartners(requestCount);
        this.requestedCount = requestCount;
        this.activeRequests = this.activeRequests.map((activeRequest) => {
          if (activeRequest.key === request.key) {
            return {
              ...activeRequest,
              editing: false,
              count: requestCount,
            };
          }
          return activeRequest;
        });
        this.$apollo.queries.location.refetch();
      });*/
    },
     async approveOnHoldPartners(headCountRequest) {
      await restApi.post(`/location/getOnHoldPartners`, encodeWithParam({clientLocationID: this.$route.params.locationID})
      ).then(async (response_Data) =>{
        // console.log("data getOnHoldPartners", response_Data);
        // console.log(headCountRequest);
        let data = response_Data.data.data;
        const onHoldCandidates = data.filter((item) => item.candidateStep === "COMPLETED" && item.candidateStatus === "ON_HOLD");
        onHoldCandidates.sort((a,b) => {
         let date1 = new Date(a.date);
          let date2 = new Date(b.date);
          if (date1 > date2) return 1;
          if (date1 < date2) return -1;
        });
      let partnersToBeApproved = headCountRequest < onHoldCandidates.length ? onHoldCandidates.slice(0,headCountRequest) : onHoldCandidates;
        let CandidateApprovedArr = [], CandidateAutoArr = [], CandidateNewlyApprovedArr = [];
        partnersToBeApproved.map((partner) => {
          const candidateProgressCompleted = this.candidateProgressObject("COMPLETED", "APPROVED",partner.id,partner.assignedOS);
          CandidateApprovedArr.push(candidateProgressCompleted);
          const candidateProgress = this.candidateProgressObject("APPROVED", "AUTO",partner.id,partner.assignedOS);
          CandidateAutoArr.push(candidateProgress);
          CandidateNewlyApprovedArr.push({
            candidateID: partner.id,
            status: "NEWLY_APPROVED",
            updateDate: DateTime.utc().toISO(),
          });
          // partnersToBeApproved.map(async (partner) => {
          // const candidateProgressCompleted = this.candidateProgressObject("COMPLETED", "APPROVED",partner.id,partner.assignedOS);
          // await this.updateCandidateProgress(candidateProgressCompleted);
          // const candidateProgress = this.candidateProgressObject("APPROVED", "AUTO",partner.id,partner.assignedOS);
          // await this.updateCandidateProgress(candidateProgress);
          // await restApi.post(`/location/markCandidateNewlyApproved`, encodeWithParam({
          //   candidateID: partner.id,
          //   status: "NEWLY_APPROVED",
          //   updateDate: DateTime.utc().toISO(),
          // }));
        //   this.$apollo.mutate({
        //   mutation: MARK_CANDIDATE_STATUS,
        //   variables: {
        //     candidateID: partner.id,
        //     status: "NEWLY_APPROVED",
        //     updateDate: DateTime.utc().toISO(),
        //   },
        // });
          
        });
        await this.updateCandidateProgress(CandidateApprovedArr);
        await this.updateCandidateProgress(CandidateAutoArr);
        await restApi.post(`/location/markCandidateNewlyApproved`, encodeWithParam({ids:CandidateNewlyApprovedArr, status: "NEWLY_APPROVED"}));
      });
        // this.$apollo.query({
      //   query: GET_ON_HOLD_CANDIDATES,
      //   variables: {
      //     clientLocationID: this.$route.params.locationID
      //   }
      //   
      //   partnersToBeApproved.map(async (partner) => {
      //     const candidateProgressCompleted = this.candidateProgressObject("COMPLETED", "APPROVED",partner.id,partner.candidateProgress[0].assignedOS);
      //     await this.updateCandidateProgress(candidateProgressCompleted);
      //     const candidateProgress = this.candidateProgressObject("APPROVED", "AUTO",partner.id,partner.candidateProgress[0].assignedOS);
      //     await this.updateCandidateProgress(candidateProgress);
      //     this.$apollo.mutate({
      //     mutation: MARK_CANDIDATE_STATUS,
      //     variables: {
      //       candidateID: partner.id,
      //       status: "NEWLY_APPROVED",
      //       updateDate: DateTime.utc().toISO(),
      //     },
      //   });
      //       this.$apollo.mutate({
      //   mutation: SET_APPROVAL_DATE,
      //   variables: {
      //     clientLocationPartnerID: partner.id,
      //     approvalDate: DateTime.utc().toJSDate(),
      //   },
      // });
      //   })
      // })
    },
    updateCandidateProgress(candidateProgress) {
      restApi.post(`/location/updateCandidateProgress`, encodeWithParam({ids:candidateProgress}));
      // this.$apollo.mutate({
      //   mutation: CREATE_CANDIDATE_PROGRESS,
      //   variables: {
      //     candidateProgress,
      //   },
      // });
    },
    candidateProgressObject(step, status,id,os) {
      return {
        assignedOS: os,
        candidateID: id,
        candidateStep: step,
        candidateStatus: status,
        date: DateTime.utc().toJSDate(),
      };
    },
    calculateWeekDay(timePeriod) {
      const days = ["weekdays", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
      const clientTimePeriod = timePeriod?.filter((item) => item.clientID != null);
      const { startDay, endDay } = clientTimePeriod?.length > 0 ? clientTimePeriod[0] : '';
      this.startDayWeek = days.includes(startDay) ? days.indexOf(startDay) : 6;
      this.endDayWeek = days.includes(endDay) ? days.indexOf(endDay) : 5;
    },

  },
  // apollo: {
  //   location: {
  //     fetchPolicy: "no-cache",
  //     query: GET_LOCATION_REQUESTS,
  //     variables() {
  //       return {
  //         locationID: this.$route.params.locationID,
  //         // startDate: DateTime.local(DateTime.now()).startOf("week").minus({ days: 2 }).toISODate(),
  //         clientID: process.env.CLIENT_ID,
  //       };
  //     },
  //     result({ data }) {
  //       const timePeriod = data.timePeriods;
  //       const location = data.location;
  //       if (location && location.headCountRequests) {
  //         this.activeRequests = this.activeWeeks.map((week) => {
  //           if (week.key === "thisweek") {
  //             const local = DateTime.local().setZone("America/Los_Angeles");
  //             const rezoned = local.setZone("America/Los_Angeles").set({ weekday: 4, hour: 12, minutes: 0, seconds: 0 });
  //             const locked = rezoned < local;
  //             week.locked = locked;
  //           }
  //           const findWeek = this.location.headCountRequests.findIndex((headCountRequest) => {
  //             return this.$options.filters.formatDate(headCountRequest.startDate) === this.$options.filters.formatDate(week.startDate);
  //           });
  //           if (findWeek >= 0) {
  //             const request = this.location.headCountRequests[findWeek];
  //             return { ...request, ...week };
  //           }
  //           return { ...week };
  //         });
  //       } else {
  //         this.activeRequests = this.activeWeeks;
  //       }
  //       this.calculateWeekDay(timePeriod);
  //     },
  //   },
  // },
};
</script>
